.animated {
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  opacity: 1;

}

@-webkit-keyframes bounceIn {
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
  }
}

@keyframes bounceIn {
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
  }
}

.promo-popup {
  display: block;
  opacity: 0;
  position: fixed;
  right: 24px;
  bottom: 10px;
  background: #fff;
  box-shadow: 0 0 10px rgba(24, 29, 39, .1), 0 15px 30px rgba(24, 29, 39, .1), 0 5px 10px rgba(24, 29, 39, .05);
  z-index: 2000;
  overflow: hidden;
  max-width: 430px;
  width: 95%;
  max-height: 430px;
  border-radius: 7px;
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

.promo-popup {
  bottom: 10px;
  opacity: 1
}

.promo-popup .up {
  display: block
}

.promo-popup .close {
  display: block;
}

.promo-popup .pp-intro-bar {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 3px;
  padding: 10px 0;
  padding-left: 20px;
  position: relative;
  transition: padding 280ms ease-in-out;
  line-height: 1.5;
}

.promo-popup .pp-intro-bar:hover {
  cursor: pointer;
}

.promo-popup .pp-intro-bar .close,
.promo-popup .pp-intro-bar .up {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  line-height: 36px;
  width: 32px;
  text-align: center;
  transition: all 250ms ease-in-out;
  color: #fff;
}

.promo-popup .pp-intro-bar .close i,
.promo-popup .pp-intro-bar .up i {
  opacity: .7;
  font-size: 17px;
  transition: all 250ms ease-in-out;
}

.promo-popup .pp-intro-bar .close:hover,
.promo-popup .pp-intro-bar .up:hover {
  cursor: pointer;
  background: #143efe;
}

.promo-popup .pp-intro-bar .close:hover i,
.promo-popup .pp-intro-bar .up:hover i {
  opacity: 1;
}

.promo-popup .pp-intro-bar .up {
  line-height: 26px;
}

.promo-popup .pp-intro-bar .up i {
  top: 9px;
}

.promo-popup .pp-intro-bar .close i {
  top: -1px;
}

.promo-popup .pp-inner-content {
  padding: 5px 20px;
}

.promo-popup .pp-inner-content::-webkit-scrollbar {
 width: 5px;
}

.promo-popup .pp-inner-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.promo-popup .pp-inner-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.promo-popup .pp-inner-content p {
  font-size: 12px;
  margin-bottom: 17px;
  line-height: 1.4;
  color: #5d6f82;
}

.pricing-enter {
  opacity: 0.01;
}

.pricing-enter.pricing-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.pricing-leave {
  opacity: 1;
}

.pricing-leave.pricing-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-out;
}


@media (max-width:500px) {
  .promo-popup {
    left: 10px;
    bottom: 10px;
  }
}

/* Overrides for bootstrap */

.table td {
  vertical-align: middle;
}

.pricing_table_icon {
  font-size: 20px;
}

.pricing_collapse_content {
  font-size: 14px;
}

.add_pointer:hover {
  cursor: pointer;
}